import React from 'react'
import Hero from '../Hero'
import HubspotIcon from './HubspotIcon'

const HeroHubspot = ({ heroTextTitle, heroTextSmallText, ctaTitle, heroData }) => {
    return (
        <Hero
            heroTextTitle={heroTextTitle}
            heroTextSmallText={heroTextSmallText}
            ctaTitle={ctaTitle}
            heroData={heroData}
        >
            <HubspotIcon />
        </Hero>
    )
}

export default HeroHubspot
