import React from "react"
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs"
import Layout from "../../components/layout"
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock"
import TextImageJamStack from "../../components/ServiceSinglePage/TextImage/TextImageJamStack"
import ServiceCTA from "./../../components/ServiceLanding/CTA/ServiceCTA"
import HeroHubspot from "../../components/ServiceSinglePage/Hero/Hubspot/HeroHubspot"
import { SEO } from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import FAQ from "../../components/FAQ/FAQ"
import faqs from "../../data/faq.json"
import { Helmet } from "react-helmet"
import ThreeColumnContent from "../../components/ThreeColumnContent"
import localisationData from '../../data/localisation.json'

const heroTextTitle = "Hubspot - The all in one inbound marketing tool"
const heroTextSmallText =
    "Our team has been working with Hubspot since 2014, we’ve built high converting websites using the Hubspot CMS, completed successful data migrations, and worked with various HubSpot services such as CRM integration, form and live chat systems into new and pre-existing websites."
const ctaTitle = "HubSpot is Crafted for Customer Experience"
const heroData = [
    "HubSpot is a cloud-based CRM designed to help bring together sales and marketing teams, improve sales engagement, boost ROI and optimise your inbound marketing strategy to generate more, qualified leads.",
    "HubSpot was made to meet your business needs without adding unnecessary complexity. Their easy to use suite of tools empower your business to grow and scale.",
    "Saigon Digital can help your business grow in many ways using the Hubspot Suite such as HubSpot CMS development, inbound marketing, onboarding, CRM implementation, consultancy, data migration, integrations and much more.",
    "Harness the power of Hubspot for your inbound marketing efforts and let the platform handle the tricky parts so you can focus on your business goals."
]
const iconBlockTitle = "How can Hubspot help Grow your business?"
const icons = [
    {
        name: "computer",
        title: "HubSpot CMS Development",
        text: "Let us help you build engaging websites built for conversions and it integrates seamlessly with the rest of the CRM."
    },
    {
        name: "content",
        title: "Developer friendly workflows & APIs",
        text: "Extend the functionality of your website with HubDB, HubSpot APIs, integrations, and the App Marketplace."
    },
    {
        name: "arising",
        title: "Hubspot CRM",
        text: "The hubspot CRM is designed to be easy to use, generate highly qualified leads to grow your company."
    },
    {
        name: "chat",
        title: "Live Chat & Chatbot Workflows",
        text: "Your website is already working for you 24/7. Let it engage and nurture leads for you now, too."
    },
    {
        name: "shield",
        title: "CMS Drag and drop editing",
        text: "A simple drag and drop interface that makes it easy for you to customise your web pages, all whilst being developer friendly and flexible for your marketing team."
    }
]
const cta1 = {
    title: "Bespoke Hubspot CMS Development",
    description:
        "We develop tailored Hubspot solutions for businesses that need to build a better customer experience and grow their inbound marketing. With our team of experts we are ready to help elevate your online presence and help generate you more qualified leads with the Hubspot platform."
}
const cta2 = {
    title: "Start your Hubspot journey with us today, see how we can help."
}

const Hubspot = ({ location }) => {
    const context = {
        pageName: "Service | Hubspot Development",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <HeroHubspot
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <ServiceIconBlock title={iconBlockTitle} icons={icons} />
            <TextImageJamStack
                title={cta1.title}
                description={cta1.description}
            >
                <StaticImage
                    alt="hubspot"
                    src="../../components/ServiceSinglePage/images/hubspot-text-image.svg"
                />
            </TextImageJamStack>
            <div className="pt-20 md:pt-0">
                <ServiceCTA title={cta2.title} />
            </div>
            <ThreeColumnContent localisationData={localisationData.hubspot}/>

            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.hubspotFaqs} />
            </div>
            <LatestBlogs />
        </Layout>
    )
}

export default Hubspot
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: [
                        {
                            "@type": "Question",
                            name: "Why choose Saigon Digital for HubSpot CMS services?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Saigon Digital stands out for HubSpot CMS services with our:\n- Custom, scalable solutions\n- Skilled HubSpot CMS developers\n- Agile project management\n- Competitive pricing"
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What is HubSpot CMS?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "HubSpot CMS is an intuitive content management system designed for website creation and management. It integrates smoothly with HubSpot’s marketing and CRM tools, making web development straightforward. This platform allows marketers to efficiently oversee their site and benefits from a comprehensive approach that includes marketing, sales, and customer service."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Why should you choose HubSpot CMS for your website?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "HubSpot CMS simplifies the process of managing your website while integrating powerful marketing features. It supports easy content creation, personalised content delivery, and visitor tracking. This all-in-one solution enhances user experience and helps in converting leads into customers by combining website management with CRM functionalities."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What features does HubSpot CMS offer?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "HubSpot CMS comes with a range of features such as drag-and-drop page builders, responsive design templates, content scheduling, advanced SEO tools, and detailed analytics. It also integrates seamlessly with HubSpot’s CRM and marketing automation, enabling businesses to build and maintain effective websites that engage and convert visitors."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Is HubSpot CMS effective for SEO?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Indeed, HubSpot CMS is well-suited for SEO. It provides features like customisable URLs, responsive templates, and built-in SEO recommendations to improve your site’s visibility on search engines and attract organic traffic."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What should I know about HubSpot CMS pricing?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "HubSpot CMS pricing varies based on the plan you select. Saigon Digital offers different tiers with its own features and pricing structure. For detailed information, contact Saigon Digital now."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How does HubSpot CMS compare to WordPress?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "HubSpot CMS surpasses WordPress by providing a more extensive marketing toolkit, a simpler user experience, and a greater emphasis on attracting and converting customers through inbound marketing tactics. It offers a streamlined approach to web development with robust analytics and marketing automation, whereas WordPress, while highly customisable, may require more manual setup and additional plugins for similar functionality."
                            }
                        }
                    ]
                })}
            </script>
        </Helmet>
        <SEO
            title={
                "Hubspot Development | Certified Hubspot Developers | Saigon Digital"
            }
            description={
                "Harness the power of Hubspot for your inbound marketing efforts and let the platform handle the tricky parts so you can focus on your business goals."
            }
        />
    </>
)
