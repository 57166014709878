import Piece1 from '@images/serviceHero/hubspot/hubspot-piece-1.svg'
import Piece2 from '@images/serviceHero/hubspot/hubspot-piece-2.svg'
import Piece3 from '@images/serviceHero/hubspot/hubspot-piece-3.svg'
import Piece4 from '@images/serviceHero/hubspot/hubspot-piece-4.svg'
import Piece5 from '@images/serviceHero/hubspot/hubspot-piece-5.svg'
import Piece6 from '@images/serviceHero/hubspot/hubspot-piece-6.svg'
import Piece7 from '@images/serviceHero/hubspot/hubspot-piece-7.svg'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import React, { useEffect, useRef } from 'react'

const HubspotIcon = () => {
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)
    useEffect(() => {
        const element = ref.current
        gsap.fromTo(
            element.querySelector('.piece-1'),
            {
                top: '20px',
                left: '20px',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 269px)',
                left: 'calc(50% - 106px)',

                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-2'),
            {
                top: '60px',
                left: '40%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 252px)',
                left: 'calc(50% + 45px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-3'),
            {
                top: '20%',
                left: '75%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 202px)',
                left: 'calc(50% + 62px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-4'),
            {
                top: '33%',
                left: '70%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 170px)',
                left: '50%',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-5'),
            {
                top: '20%',
                left: '35%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 232px)',
                left: 'calc(50% - 64px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-6'),
            {
                top: '33%',
                left: '40px',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 31px)',
                left: 'calc(50% - 41px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-7'),
            {
                top: '40%',
                left: '40%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 57px)',
                left: 'calc(50% - 12px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
    }, [])
    return (
        <div
            ref={ref}
            className="hidden xl:block relative md:col-[1_/_2] md:row-[2_/_4]"
        >
            <div className="absolute top-[10%] wrap-pice"></div>
            <div className="piece-1 absolute z-[2] w-[calc(965px*0.06)]">
                <img alt="hubspot-piece" src={Piece1} />
            </div>
            <div className="piece-2 absolute z-[2] w-[calc(890px*0.06)]">
                <img alt="hubspot-piece" src={Piece2} />
            </div>
            <div className="piece-3 absolute z-[2] w-[calc(348px*0.06)]">
                <img alt="hubspot-piece" src={Piece3} />
            </div>
            <div className="piece-4 absolute z-[2] w-[calc(2414px*0.06)]">
                <img alt="hubspot-piece" src={Piece4} />
            </div>
            <div className="piece-5 absolute z-[2] w-[calc(1797px*0.06)]">
                <img alt="hubspot-piece" src={Piece5} />
            </div>
            <div className="piece-6 absolute z-[2] w-[calc(747px*0.06)]">
                <img alt="hubspot-piece" src={Piece6} />
            </div>
            <div className="piece-7 absolute z-[2] w-[calc(717px*0.06)]">
                <img alt="hubspot-piece" src={Piece7} />
            </div>
        </div>
    )
}

export default HubspotIcon
